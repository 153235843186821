import React, { Component } from "react";


class About extends Component {

	render() {
		return (
		<div>
		<div>AppSearch is a utility to quickly find the bundleID of apps</div>
		<div>This app is written in ReactJS and pulls data from the iTunes search and lookup APIs</div>
		</div>
		);
	}
}

export default About;